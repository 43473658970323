import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components/Layout';
import OrderForm from '../components/OrderForm';

const OrderDevelopmentPermitSurvey = () => (
  <Layout
    title="Request a quote and more information about a Development Permit survey in Calgary, Red Deer, Edmonton. Rockyview, Foothills."
    description="Building a new garage , or a complete new infill development? Duplex, single family or multi family, you will need more info about Development Permit surveys? Reach out ot Axiom Geomatics team and we can help you sort through the many muciicple requirements."
    keywords=""
  >
    <main>
      <h1>Request a Quote for a Development Permit Survey</h1>

      <p>
        Thinking of doing some construction, eh? We can get that Development Permit Survey for you,
        no problem. Just let us know who you are and some info about the property you need the
        report for, and we'll contact you before geting started - expect us to contact you within
        one business day. Need more information about{' '}
        <Link to="/services/construction-services/development-permits">Development Permits</Link>?
      </p>

      <OrderForm type="dp" />
    </main>
  </Layout>
);

export default OrderDevelopmentPermitSurvey;
